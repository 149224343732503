import React, { useState, useEffect } from 'react';

import logo from './logo.svg';
import './App.css';
import './antd.css';
import  { whoAmI } from "./Config";
import axios from 'axios';
import * as qs from 'query-string';

import { apiRoot } from "./Config";

import { Button, Checkbox, Form, Input, message } from 'antd';
import store from 'store2';
import { useHistory, useParams } from 'react-router-dom'

function Links() {
  const urlParams = qs.parse(window.location.search);
  const params = useParams();

  useEffect(() => {
   
  });

  return (
    <div>
        <h1>Please follow the links</h1>
        <p><Button type="link" href="https://misc.newgen.co/hr/emails/request/create">Manage Email Accounts</Button></p>
        <p><Button type="link" href="https://misc.newgen.co/hr/insurance/status/waiting-for-response">Outstanding GMC Acknowledgement</Button></p>
    </div>
  );
}

export default Links;