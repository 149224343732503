import AWS from 'aws-sdk'

let apiRoot = "https://pubkit.newgen.co/";

if(window.location.host==="localhost:3000")
{
    apiRoot = "http://localhost:8086/";
}

if(window.location.host==="uatfreelancer.web.app")
{
    apiRoot = "https://pubkittest.newgen.co/";
}


export const whoAmI = apiRoot + "submissions/freelancer/api/whoami";

//https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/s3-example-creating-buckets.html
const credentials = new AWS.CognitoIdentityCredentials({IdentityPoolId:"us-east-1:086c4450-fa44-46ac-ac22-bcba47a1007a"});
const S3_BUCKET ='newgen-pk-prod';
const REGION ='us-east-1';

AWS.config.update({
    region:REGION,
    credentials:credentials
}
);

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})

AWS.config.credentials.refresh();

export { apiRoot, myBucket, AWS }