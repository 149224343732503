import logo from './logo.svg';
import './App.css';
import './antd.css';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import {
    Layout,
    Menu,
    Radio,
    TreeSelect,
    DatePicker,
    Switch,
    AutoComplete,
    Button,
    Cascader,
    Checkbox,
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    MenuProps,
    Table,
    Modal,
    Upload,
    message,
    Space,

} from 'antd';

import AWS from 'aws-sdk'
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import store from 'store2';
import { apiRoot } from "./Config";
import MailHeader from './component/header'

const { Search } = Input;

//https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/s3-example-creating-buckets.html
const credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: "us-east-1:d0488ef8-af13-4ba0-9c1c-aebd3215c422" });
const S3_BUCKET = 'newgen-ae-dev';
const REGION = 'us-east-1';

AWS.config.update({
    region: REGION,
    credentials: credentials
}
);

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

AWS.config.credentials.refresh();

var headers = store.get("pubkit_auth");


const unithead = {
    "Subhash": "subhashp@newgen.co",
    "Gowrishankar": "gowrishankar@newgen.co",
    "Srikanth GS": "srikanth@newgen.co",
    "Jose": "jose@newgen.co",
    "Ashokumar P": "ashokumar@newgen.co",
    "Jo Bottrill": "jo@newgenpublishing.co.uk",
    "Dinesh R": "rdinesh@newgen.co",
    "Krishna Kumar M": "krishnakumar@newgen.co",
    "Anupama G": "anupama@newgen.co",
    "Deivanayagam": "deiva@newgen.co",
    "Muralidharan P": "murali@newgen.co",
    "Saravanan": "saran@newgen.co",
    "Dipak": "dipak.shaw@newgen.co",
    "Anand Jain": "anandjain@newgen.co",
    "Maran": "maran@newgen.co",
    "Deepali": "deepali.sawant@newgen.co",
    "Madhavan": "pkmadhu@vijaynicole.co.in",
    "Arnab Ghosh": "arnab.g@newgen.co",
    "Jason": "jason@newgen.co",
    "Kannan NDW": "kannan@newgen.co",
    "Raja Subramanian": "saran@newgen.co",
    "Senthilkumar CFO": "senthil.kumar@newgen.co"
}

const { Header, Sider, Content } = Layout;
const { Option } = Select;

export default function DomainMails(props) {
    const [form] = Form.useForm();
    const [collapsed, setCollapsed] = useState(false);
    const params = useParams();
    const [page, setPage] = useState([]);
    const [data, setData] = useState([]);
    const [employeeData, setEmployeeData] = useState({});
    const [freelancers, setFreelancers] = useState([]);
    const [newDependentForm, setNewDependentForm] = useState("none");
    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [emailsForGroup, setEmailsForGroup] = useState([]);
    const [emailsForAlias, setEmailsForAlias] = useState([]);
    const [moreInfo, setMoreInfo] = useState("");
    const [requestedData, setRequestedData] = useState([]);
    const [aliasChildren, setAliasChildren] = useState([]);
    const [aliasChildrenDetaults, setAliasChildrenDetaults] = useState([]);
    
    
    


    useEffect(() => {
        console.log(params);

        if(params.page=="request")
        {
            var apiUrl = `${apiRoot}office/api/domainmails/find/bot-status-less-than?q=99`;
            axios.get(apiUrl, { headers }).then(res => {
                setRequestedData(res.data);
            }).catch(function (error) {
                
              if(error.response.status==401){
                window.location.href = "/";
              }});
        }
        if(params.page=="distribution-list")
        {
            var apiUrl = `${apiRoot}office/api/domainmails/find/mail-boxes`;
            axios.get(apiUrl, { headers }).then(res => {
                setEmailsForGroup(res.data);
            });
        }

        if(params.page=="alisas")
        {
            var apiUrl = `${apiRoot}office/api/domainmails/find/for-group`;
            axios.get(apiUrl, { headers }).then(res => {
                setEmailsForAlias(res.data);
            });
        }

    }, []);


    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="86">+86</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );
    const suffixSelector = (
        <Form.Item name="suffix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="USD">$</Option>
                <Option value="CNY">¥</Option>
            </Select>
        </Form.Item>
    );
    const [autoCompleteResult, setAutoCompleteResult] = useState([]);

    const onWebsiteChange = (value) => {
        if (!value) {
            setAutoCompleteResult([]);
        } else {
            setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
        }
    };

    const websiteOptions = autoCompleteResult.map((website) => ({
        label: website,
        value: website,
    }));

    const items1: MenuProps['items'] = ['1', '2', '3'].map(key => ({
        key,
        label: `nav ${key}`,
    }));


    return (
        <Layout>
            <Header
                className="site-layout-background"
                style={{
                    padding: 0,
                }}
            >
                <div className="logo" />
            </Header>
            <Layout className="site-layout">
                <Content
                    className="site-layout-background"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: '100vh',
                    }}
                >

                    {/* {(params.page == "details") && (renderDashboard())} */}
                    {(params.page == "request") && (renderInitialScreen())}
                    {(params.page == "employee") && (renderEmployeeScreen())}
                    {(params.page == "team") && (renderTeamScreen())}
                    {(params.page == "distribution-list") && (renderDistributionScreen())}
                    {(params.page == "alisas") && (renderAlisasScreen())}
                    {(params.page == "delete") && (renderDeleteScreen())}


                </Content>
            </Layout>
        </Layout>
    );



    function renderInitialScreen() {
        const onUnitHeadChange = (value) => {
            console.log(value)
        };

        const onMailforChange = (value) => {
            console.log(value)
        };

        const onFinish = (values) => {
            console.log(values);
            window.location.href = "/hr/emails/" + values.emailfor + "/" + values.unithead
        };

        const onReset = () => {
            form.resetFields();
        };

        const moreInfoOfEmail = (e) =>
        {
            console.log(e);
            if(e==null) { return; }
            var emailPrefix = e.trim().toLowerCase();
            emailPrefix = emailPrefix.replace("mailto:", "");
            var apiUrl = `${apiRoot}office/api/domainmails/moreinfo/`+emailPrefix;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                var html = "<table class='table table-striped'><tbody>";
                for(var prop in res.data.email)
                {
                    html += "<tr><td>"+prop+"</td><td>"+res.data.email[prop]+"</td></tr>";
                }
                html += "<tbody></table>";
                if(res.data.email===undefined)
                {
                    html = "Record not found";
                }
                setMoreInfo(html);
            });
        }



        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <Form form={form} name="control-hooks" onFinish={onFinish} layout="vertical">
                        <Form.Item
                            name="unithead"
                            label="Enter Business Unit Head Name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}

                        >
                            <Select
                                placeholder=" "
                                onChange={onUnitHeadChange}
                                allowClear
                            >
                                {Object.keys(unithead).map((item) => {
                                    return <Option value={item}>{item}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="emailfor"
                            label="Enter the email for"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                placeholder=" "
                                onChange={onMailforChange}
                                allowClear
                            >
                                <Option value="employee">EMPLOYEE</Option>
                                <Option value="team">TEAM</Option>
                                <Option value="distribution-list">DISTRIBUTION LIST</Option>
                                <Option value="alisas">ALISAS</Option>
                                <Option value="delete">DELETE</Option>

                            </Select>
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
                
                <div style={{marginTop:"50px"}}>
                    <h3>The following documents may be useful</h3>
                    <div>
                        <Button type="link" onClick={(e) => window.open('https://docs.google.com/presentation/d/1WyAnzxk2EkY0wHFGLPSR-RiX-hOYWT7mWqDXvIv934k/edit#slide=id.g13d60fdf4b3_0_0', "_blank")} size={10}>Types of emails</Button>
                    </div>
                </div>
                
                <Card style={{marginTop:"50px"}}>
                <div>
                    <h6>Get info about email</h6>
                    <div>
                    <Search
                        placeholder="input search text"
                        allowClear
                        onSearch={(e) => moreInfoOfEmail(e)}
                        style={{width: 500}}
                        />
                    </div>
                    <div style={{marginTop:"30px"}} dangerouslySetInnerHTML={{__html: moreInfo}}>
                    </div>
                </div>
                </Card>

                <Card style={{marginTop:"50px"}}>
                <div>
                <h6>Open Requests</h6>
                <Table rowKey="id" columns={
                    [
                        {
                            key: "name",
                            title: 'Name',
                            dataIndex: 'name'
                        },
                        {
                            key: "type",
                            title: 'Type',
                            dataIndex: 'type'
                        },
                        {
                            key: "exchange",
                            title: 'Exchange',
                            dataIndex: 'exchange'
                        },
                        {
                            key: "action",
                            title: 'Action',
                            dataIndex: 'action'
                        },
                        {
                            key: "createdAt",
                            title: 'Created At',
                            dataIndex: 'createdAt'
                        }
                    ]
                } dataSource={requestedData} pagination={false} />
                </div>
                </Card>


            </div>
        )
    }



    function renderEmployeeScreen() {

        const onFinish = (value) => {
            console.log(value);

            var emailPrefix = value["emailPrefix"];
            emailPrefix = emailPrefix.replace("mailto:", "");
            emailPrefix = emailPrefix.split("@")[0];

            var apiUrl = `${apiRoot}office/api/domainmails/preferred-email?q=`+emailPrefix;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                if(res.data["id"]!==0)
                {
                    message.info('The email id is not avaiable');
                    return;
                }
                value["buhead"] = params.unithead;
                value["request"] = params.page;
                value["request"] = value["request"].toUpperCase();
                value["email"] = emailPrefix.split("@")[0];
                value["primaryEmail"] = "";
                value["botStatus"] = 0;
                value["type"] = "EMPLOYEE";
                value["action"] = "EMPLOYEE MAIL CREATION";
                value["communication"] = "internal";
                if(value["exchange"]=="GAPPS")
                {
                    value["communication"] = "external";
                    value["email"] = value["email"] + "@newgen.co";
                }
                else
                {
                    value["email"] = value["email"] + "@nkw.pub";
                }
                value["email"] = value["email"].trim().toLowerCase();
                
                axios.post(`${apiRoot}office/api/domainmails/add`, value, { headers }).then(res => {
                    console.log(res.data)
                    message.info('Your request will be addressed in 24 hours');
                });

            });


        }
        const onReset = () => {
            form.resetFields();
        };

        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <div className='formheader'>EMPLOYEE DETAILS</div>
                    <Form form={form} name="control-hooks" onFinish={onFinish} layout="vertical">
                        <Form.Item
                            label="EMPLOYEE FIRST NAME"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="EMPLOYEE LAST NAME"
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="EMPLOYEE ID"
                            name="employeeId"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="ENTER THE PREFERRED EMAIL ADDRESS"
                            name="emailPrefix"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="exchange" label="Is the new email address required in Gmail or Zimbra?"   rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Radio.Group>
                                <Radio value="GAPPS">GAPPS</Radio>
                                <Radio value="ZIMBRA">ZIMBRA</Radio>

                            </Radio.Group>
                        </Form.Item>

                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button style={{ marginLeft: '10px' }} type="text" htmlType="button" onClick={(e) => {window.location.href = "/hr/emails/request/new"}}>
                                Go back
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }

    function renderTeamScreen() {

        const onFinish = (value) => {
            console.log(value);

            var emailPrefix = value["emailPrefix"];
            emailPrefix = emailPrefix.replace("mailto:", "");
            emailPrefix = emailPrefix.split("@")[0];

            var apiUrl = `${apiRoot}office/api/domainmails/preferred-email?q=`+emailPrefix;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                if(res.data["id"]!==0)
                {
                    message.info('The email id is not avaiable');
                    return;
                }
                value["buhead"] = params.unithead;
                value["request"] = params.page;
                value["request"] = value["request"].toUpperCase();
                value["email"] = emailPrefix.split("@")[0];
                value["primaryEmail"] = "";
                value["type"] = "TEAM";
                value["employeeId"] = "9102083";
                value["name"] = "TEAM";
                value["lastName"] = "";
                value["action"] = "TEAM REQUEST PLACED";
                value["communication"] = "internal";
                if(value["exchange"]=="GAPPS")
                {
                    value["communication"] = "external";
                    value["email"] = value["email"] + "@newgen.co";
                }
                else
                {
                    value["email"] = value["email"] + "@nkw.pub";
                }
                value["email"] = value["email"].trim().toLowerCase();
                axios.post(`${apiRoot}office/api/domainmails/add`, value, { headers }).then(res => {
                    console.log(res.data)
                    message.info('Your request will be addressed in 24 hours');
                });

            });

        }
        const onReset = () => {
            form.resetFields();
        };

        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <div className='formheader'>TEAM DETAILS</div>
                    <Form form={form} name="control-hooks" onFinish={onFinish} layout="vertical">
                       
                        
                        
                        <Form.Item
                            label="ENTER THE PREFERRED EMAIL ADDRESS"
                            name="emailPrefix"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="exchange" label="Is the new email address required in Gmail or Zimbra?"   rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Radio.Group>
                                <Radio value="GAPPS">GAPPS</Radio>
                                <Radio value="ZIMBRA">ZIMBRA</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button style={{ marginLeft: '10px' }} type="text" htmlType="button" onClick={(e) => {window.location.href = "/hr/emails/request/new"}}>
                                Go back
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }

    function renderDistributionScreen() {

        const children = [];
        var uniqueEmails = [];

        for (let i = 0; i < emailsForGroup.length; i++) {
            uniqueEmails.push(emailsForGroup[i]["primaryEmail"]);
        }
        uniqueEmails = [...new Set(uniqueEmails)]

        for (let i = 0; i < uniqueEmails.length; i++) {
            children.push(<Option key={uniqueEmails[i]}>{uniqueEmails[i]}</Option>);
        }

        const handleChange = () =>
        {

        }

        const onDistributionFinish = (value) => {
            console.log(value);

            var emailPrefix = value["emailPrefix"];
            emailPrefix = emailPrefix.replace("mailto:", "");
            emailPrefix = emailPrefix.split("@")[0];

            var apiUrl = `${apiRoot}office/api/domainmails/preferred-email?q=`+emailPrefix;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                if(res.data["id"]!==0)
                {
                    message.info('The group id is already available. The recipients email addres will be included');
                }
                value["buhead"] = params.unithead;
                value["request"] = params.page;
                value["request"] = value["request"].toUpperCase();
                value["email"] = emailPrefix.split("@")[0];
                value["primaryEmail"] = "";
                value["type"] = "GROUP";
                value["employeeId"] = "9102083";
                value["name"] = "GROUP";
                value["action"] = "GROUP REQUEST PLACED";
                value["communication"] = "internal";
                if(value["exchange"]=="GAPPS")
                {
                    value["communication"] = "external";
                    value["email"] = value["email"] + "@newgen.co";
                }
                else
                {
                    value["email"] = value["email"] + "@nkw.pub";
                }
                value["email"] = value["email"].trim().toLowerCase();

                axios.post(`${apiRoot}office/api/domainmails/add`, value, { headers }).then(res => {
                    console.log(res.data)
                    message.info('Your request will be addressed in 24 hours');
                });

            });

        }

        const onReset = () => {
            form.resetFields();
        };

        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <div className='formheader'>DISTRIBUTION DETAILS</div>
                    <Form form={form} name="control-hooks" onFinish={onDistributionFinish} layout="vertical">
                       <Form.Item
                            label="ENTER THE PREFERRED EMAIL ADDRESS"
                            name="emailPrefix"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                            
                        </Form.Item>
                        <Form.Item
                            label="Enter the recipients email addresses with comma separator"
                            name="members"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
      mode="multiple"
      allowClear
      style={{
        width: '100%',
      }}
      placeholder="Please select"
      onChange={handleChange}
    >
      {children}
    </Select>
                            
                        </Form.Item>
                        <Form.Item name="exchange" label="Is the new email address required in Gmail or Zimbra?"   rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Radio.Group>
                                <Radio value="GAPPS">GAPPS</Radio>
                                <Radio value="ZIMBRA" disabled>ZIMBRA</Radio>

                            </Radio.Group>
                        </Form.Item>

                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button style={{ marginLeft: '10px' }} type="text" htmlType="button" onClick={(e) => {window.location.href = "/hr/emails/request/new"}}>
                                Go back
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }
    function renderAlisasScreen() {

        const children = [];
        var uniqueEmails = [];

        for (let i = 0; i < emailsForAlias.length; i++) {
            uniqueEmails.push(emailsForAlias[i]["primary_email"]);
        }
        uniqueEmails = [...new Set(uniqueEmails)];

        for (let i = 0; i < uniqueEmails.length; i++) {
            children.push(<Option key={uniqueEmails[i]}>{uniqueEmails[i]}</Option>);
        }

        var aliasChildrens = [];
        var aliasChildrenValues = [];

        const handleChange = (primaryEmail) =>
        {
            var apiUrl = `${apiRoot}office/api/domainmails/find/for-alias?q=`+primaryEmail;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                for(var i=0; i<res.data.length; i++)
                {
                    aliasChildrens.push(<Option key={res.data[i]["email"]}>{res.data[i]["email"]}</Option>);
                    aliasChildrenValues.push(res.data[i]["email"]);
                }
                setAliasChildren(aliasChildrens);
                setAliasChildrenDetaults(aliasChildrenValues);
            });
        }

        const onFinish = (value) => {
            console.log(value);

            var emailPrefix = value["primaryEmail"];
            emailPrefix = emailPrefix.replace("mailto:", "");
            emailPrefix = emailPrefix.split("@")[0];

            var apiUrl = `${apiRoot}office/api/domainmails/preferred-email?q=`+emailPrefix;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                if(res.data["id"]==0)
                {
                    message.info('The email id is not avaiable');
                    return;
                }
                value["buhead"] = params.unithead;
                value["request"] = params.page;
                value["request"] = value["request"].toUpperCase();
                value["email"] = emailPrefix.split("@")[0];
                value["primaryEmail"] = "";
                value["emailPrefix"] = emailPrefix;
                value["type"] = "ALIAS";
                value["employeeId"] = "9102083";
                value["members"] = value["aliasAddresses"];
                value["name"] = "ALIAS";
                value["action"] = "ALIAS REQUEST PLACED";
                value["communication"] = "internal";
                if(value["exchange"]=="GAPPS")
                {
                    value["communication"] = "external";
                    value["email"] = value["email"] + "@newgen.co";
                }
                else
                {
                    value["email"] = value["email"] + "@nkw.pub";
                }
                value["email"] = value["email"].trim().toLowerCase();
                
                axios.post(`${apiRoot}office/api/domainmails/add`, value, { headers }).then(res => {
                    console.log(res.data)
                    message.info('Your request will be addressed in 24 hours');
                });

            });

        }

        const onReset = () => {
            form.resetFields();
        };

        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <div className='formheader'>ALISAS DETAILS</div>
                    <Form form={form} name="control-hooks" onFinish={onFinish} layout="vertical">
                       <Form.Item
                            label="Enter the primary mailbox"
                            name="primaryEmail"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
      allowClear
      style={{
        width: '100%',
      }}
      placeholder="Please select"
      onChange={handleChange}
    >
      {children}
    </Select>
                            
                        </Form.Item>
                        <Form.Item
                            label="Enter the alias email address"
                            name="aliasAddresses"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                            mode="tags"
      allowClear
      style={{width: '100%'}}
      placeholder="Please select"
      defaultValue={aliasChildrenDetaults}
    >
      {aliasChildren}
    </Select>
                        </Form.Item>
                        <Form.Item name="exchange" label="Is the new email address required in Gmail or Zimbra?"   rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Radio.Group>
                                <Radio value="GAPPS">GAPPS</Radio>
                                <Radio value="ZIMBRA" disabled>ZIMBRA</Radio>

                            </Radio.Group>
                        </Form.Item>

                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button style={{ marginLeft: '10px' }} type="text" htmlType="button" onClick={(e) => {window.location.href = "/hr/emails/request/new"}}>
                                Go back
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }
    function renderDeleteScreen() {

        const onFinish = (value) => {
            console.log(value);

            var emailPrefix = value["emailPrefix"];
            emailPrefix = emailPrefix.replace("mailto:", "");
            emailPrefix = emailPrefix.split("@")[0];

            var apiUrl = `${apiRoot}office/api/domainmails/preferred-email?q=`+emailPrefix;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                if(res.data["id"]==0)
                {
                    message.info('The email id is not avaiable');
                    return;
                }
                value["buhead"] = params.unithead;
                value["request"] = params.page;
                value["request"] = value["request"].toUpperCase();
                value["email"] = emailPrefix.split("@")[0];
                value["type"] = "GROUP";
                value["status"] = "DELETE";
                value["action"] = "DELETE";
                value["botStatus"] = 0;
                
                axios.post(`${apiRoot}office/api/domainmails/delete/`+res.data["fuzzyId"], value, { headers }).then(res => {
                    console.log(res.data)
                    message.info('Your request will be addressed in 24 hours');
                });

            });

        }

        const onReset = () => {
            form.resetFields();
        };

        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <div className='formheader'>EMAIL DELETE REQUEST</div>
                    <Form form={form} name="control-hooks" onFinish={onFinish} layout="vertical">
                       <Form.Item
                            label="Enter the email address to delete"
                            name="emailPrefix"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                            
                        </Form.Item>
                      
                      

                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button style={{ marginLeft: '10px' }} type="text" htmlType="button" onClick={(e) => {window.location.href = "/hr/emails/request/new"}}>
                                Go back
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }

};
