import React, { useState, useEffect } from 'react';

import './App.css';
import './antd.css';
import { whoAmI } from "./Config";
import axios from 'axios';
import * as qs from 'query-string';

import { apiRoot } from "./Config";
import { Button, Checkbox, Form, Input, message, Card, Typography, Row, Col } from 'antd';
import store from 'store2';
import { useHistory, useParams } from 'react-router-dom'
const { Paragraph, Text } = Typography;

function Login() {
  const urlParams = qs.parse(window.location.search);
  const params = useParams();

  useEffect(() => {
    console.log(urlParams);

    if (params.message !== undefined && params.message == "login") {
      message.info('Please login with your credentials.');
    }
    if (urlParams.message !== undefined && urlParams.message == "unauthorized-user") {
      message.info('Your login is unauthorised.');
    }
    if (urlParams.message !== undefined && urlParams.message == "reset-password") {
      message.info('Reset password link has been sent to your email.');
    }
    if (urlParams.message !== undefined && urlParams.message == "reset-password-done") {
      message.info('Please login using your new password.');
    }
    var freelanceAuth = store("pubkit_auth");
    var headers = { ...freelanceAuth };
    console.log(headers);

    if (headers["token"] !== undefined)
      axios.get(`${apiRoot}submissions/freelancer/api/hello`, { headers }).then(res => {
        userLandingPage();
      }).catch(err => {
        if (err.response.status == 401) {
          store.remove("pubkit_auth");
          window.location.href = "/";
        }
      });
  }, []);

  const onFinish = (values) => {
    console.log('Success:', values);
    axios.post(`${apiRoot}submissions/authenticateparams`, values)
      .then(res => {
        if (res.data.status != 200) {
          message.info('Invalid Credentails');
          return;
        }
        console.log(res.data);
        if (res.data["authToken"] == null) {
          window.location.href = "?message=unauthorized-user";
          return;
        }
        store("pubkit_auth", res.data["authToken"]);
        store("pubkit_user", res.data["user"]);
        var freelanceRole = [];
        for (var i = 0; i < res.data["capabilities"].length; i++) {
          freelanceRole.push(res.data["capabilities"][i]["name"]);
        }
        store("pubkit_role", freelanceRole);
        userLandingPage();
      }).catch(function (error) {
        message.info("Unable to connect the server at this time... Please try after sometimes...");
        console.log(error);
      });
  };


  const userLandingPage = () => {
    const freelanceRole = store.get("pubkit_role");

    var redirectPage = "freelancer/application?page=application&fuzzyId=undefined";
    if (freelanceRole.indexOf("freelance_admin") != -1) {
      redirectPage = "admin";
    }

    if (freelanceRole.indexOf("freelance_manager") != -1) {
      redirectPage = "manager/freelancers?status=selected";
    }
    window.location.href = redirectPage;
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="myfullscreen">
      <Card id="loginform">
        <img width="40%" src="https://storage.googleapis.com/newgen-website-public-assets/images/home/newgenlogo.png" />
        <div id="headerTitle"><img width="10%" src="https://storage.googleapis.com/newgen-website-public-assets/icons/user1.png" /></div>
        <Form
          name="basic"
          layout="vertical"
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off" style={{ background: "white" }}>
          <div className="row">
            <label className='rowlabel'>User Name</label>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                }
              ]}>
              <Input type="text" />
            </Form.Item>
          </div>
          <div className="row">
            <label className='rowlabel'>Password</label>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                }
              ]}>
              <Input type='password' />
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{
                offset: 2,
                span: 20,
              }}>
              <Row span={24}>
                <Col span={12}><Checkbox checked={false} style={{ marginLeft: "7%" }}>Remember me</Checkbox></Col>
                <Col span={12}><Button style={{fontSize:12}} type="link" onClick={(e) => window.location.href = "/forgot"}>Forgot Password</Button></Col>
              </Row>
            </Form.Item>
            <Form.Item >
              <Button type="primary" htmlType="submit">
                LOG IN
              </Button>
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item>
              <Button onClick={(e) => window.location.href = "/register"} type="primary" htmlType="button">
                REGISTER
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div className="myfooter"><a href="https://docs.google.com/document/d/e/2PACX-1vSssehMeX9FNAP534Q-ilH4XaI-wc__B5ASKIsGye90sV3YGatOhuP-KjDwPymJQ6o5Bpiu6VyNCsmY/pub">Terms and Conditions</a> | <a href="https://docs.google.com/document/d/e/2PACX-1vSR8nGaX8FiG9JNheeZG6-4INv9vPh1uStfYqEomhlliL1hCiRFgR2ZCFVVUGMebLmIn7dgi8z6GMob/pub">Privacy Policy</a> |<br/><a href="https://newgen.co/aboutus">Newgen KnowledgeWorks (P) Ltd. All rights reserved.</a></div>
      </Card>
    </div>

  );
}
export default Login;